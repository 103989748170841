
import {IFacultyStyleConfig} from "../../Config/faculties.config";


import './directionInfo.style.sass'
import React, {useState} from "react";


interface IDirectionInfoProps {
    style: IFacultyStyleConfig
    directionInfo: any
    current_direction: number
    exams: object
    prices: object
    scores: object
    types: object
    quotas: object
    forms: object
    examScores: object
}


enum InfoBlockSize {
    small,
    middle,
    big
}

const mockBlockInfo: InfoBlockInfo[] = [
    {
        key: 'Информатика и ИКТ',
        value: '400'
    },
    {
        key: 'Информатика и ИКТ',
        value: '400'
    },
    {
        key: 'Информатика и ИКТ',
        value: '400'
    },
]

export default function DirectionInfo(props: IDirectionInfoProps) {
    let blockExamsName = {
        name: 'ЕГЭ',
        isCollege: false
    }
    if (props.style.name === 'СПбКТ') blockExamsName = {
        name: 'Прием ведется с учётом следующих предметов:',
        isCollege: true,
    }


    if (props.directionInfo !== undefined) {
    return <div className="DirectionInfo" id="direction">
        <div className="DirectionInfo_MainInfo">
            <div className="DirectionInfo_MainInfo_Image" style={{backgroundColor: props.style.colors.baseColor}}/>
            <div>
                <div className="DirectionInfo_MainInfo_FacName">{props.directionInfo.name_direction}</div>
                <div className="DirectionInfo_MainInfo_Info">
                    <span
                        className="DirectionInfo_MainInfo_Info_FacNumber">{props.directionInfo.kode_direction}</span>
                    {props.directionInfo.section}
                </div>
            </div>
        </div>

        <div className="DirectionInfo_InfoBlocks">
            <InfoBlock1mobile type={props.types} size={InfoBlockSize.middle} info={props.examScores} exams={props.exams}
                                form={props.forms} blockName={blockExamsName} icon={"1"} direction={props.current_direction}/>

            <InfoBlock1 type={props.types} size={InfoBlockSize.middle} info={props.examScores} form={props.forms} exams={props.exams}
                        blockName={blockExamsName} icon={"1"} direction={props.current_direction}/>

            <InfoBlock2 type={props.types} size={InfoBlockSize.small} info={props.scores} form={props.forms} blockName={"Проходной балл"}
                         icon={"3"} direction={props.current_direction}/>
            <InfoBlock4 type={props.types} size={InfoBlockSize.small} info={props.quotas} form={props.forms} blockName={"Количество мест"}
                         icon={"4"} direction={props.current_direction}/>
            <InfoBlock5 type={props.types} size={InfoBlockSize.small} info={mockBlockInfo} form={props.forms}
                        blockName={"Срок обучения"} icon={"2"} direction={props.current_direction}/>
            <InfoBlock3 size={InfoBlockSize.small} type={props.types} info={props.prices} form={props.forms}
                        blockName={"Стоимость обучения"} icon={"5"} direction={props.current_direction}/>
            <InfoBlockText type={props.types} size={InfoBlockSize.big} info={props.directionInfo} form={props.forms}
                           blockName={"Краткая информация"} icon={"6"} direction={props.current_direction}/>
        </div>

    </div>
} else
    return <div/>
}


interface InfoBlockInfo {
    key: string
    value: string
}

interface IInfoBlockProps {
    size: InfoBlockSize
    blockName: string
    info: any
    icon: string
    direction: number,
    type: any,
    form: any
}

interface IInfoBlockProps1 {
    size: InfoBlockSize
    blockName: {
        name: string,
        isCollege: boolean
    }
    info: any
    icon: string
    direction: number,
    type: any,
    form: any,
    exams: any
}
function InfoBlock1(props: IInfoBlockProps1) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_Svg_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block_Svg"

    const limitExams = 4
    const [showFullExamsBlock, setFullExamsBlock] = useState(false)
    const [examsBlockSize, setExamsBlockSize] = useState(0)
    function showAllExams(){
        setFullExamsBlock(true)
        setExamsBlockSize(props.info.length*10 - 20)
    }

    function hideExams(){
        setFullExamsBlock(false)
        setExamsBlockSize(0)
    }

    return <div className={`DirectionInfo_InfoBlocks_Block_Svg ${mainClassWithSize}`}
                onMouseLeave={hideExams}
                style={{display: window.innerWidth > 1024 ? 'flex' : 'none'}}>

        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClass}_Name`}>{props.blockName.name}</div>
        <div className={props.blockName.isCollege ? `${mainClass}_Info ${mainClass}_Info_List` : `${mainClass}_Info` }>
            {
                props.info.map((info: any, infoIndex: number) => {
                    return <div key={infoIndex} className={props.blockName.isCollege ? `${mainClass}_Info_Line_College` : `${mainClass}_Info_Line`}>
                            {
                                props.exams.filter((exam: any) => exam.id === info.subject)
                                    .filter(() => showFullExamsBlock ? true : infoIndex < limitExams)
                                    .map((exam: any, index: number) => {
                                    if (props.blockName.isCollege){
                                            return <span key={index} >
                                                {exam.name}
                                                {props.info.length !== infoIndex + 1 ?
                                                          ',' : ''
                                                      }
                                            </span>
                                    }
                                    else return <div key={index} className={`${mainClass}_Info_Line_Key`}>{exam.name}</div>
                                })
                            }
                            {!props.blockName.isCollege && <div className={`${mainClass}_Info_Line_Value`}>{info.score}</div>}
                        </div>
                    }
                )}
            {(props.blockName.isCollege) && !showFullExamsBlock &&
            <div className={`${mainClass}_Info_More`} onMouseOver={showAllExams}>Еще...</div>}
            {!Array.isArray(props.info) && typeof props.info.text !== "undefined" &&
            <div className={`${mainClass}_Info_Text`}>{props.info.text}</div>}
        </div>
        <svg className="DirectionInfo_InfoBlocks_Block_Svg" preserveAspectRatio="none" width="100%" height="100%"
             viewBox="0 0 265 199" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d={`M0.5 7.7116C0.5 3.8226 3.65266 0.669922 7.54167 0.669922H88.9426C91.7175 0.669922 94.2337 2.29961 95.3685 4.83188L122.994 66.4756C124.128 69.0079 126.645 70.6375 129.419 70.6375H257.521C261.41 70.6375 264.562 73.7902 264.562 77.6792V${String(191+examsBlockSize)}.499C264.562 ${String(195+examsBlockSize)}.388 261.41 ${String(198+examsBlockSize)}.541 257.521 ${String(198+examsBlockSize)}.541H7.54166C3.65265 ${String(198+examsBlockSize)}.541 0.5 ${String(195+examsBlockSize)}.388 0.5 ${String(191+examsBlockSize)}.499V7.7116Z`}
                fill="white"/>
        </svg>
    </div>
}

function InfoBlock1mobile(props: IInfoBlockProps1) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block"
    return <div className={`DirectionInfo_InfoBlocks_Block ${mainClassWithSize}`}>
        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClass}_Name`}>{props.blockName.name}</div>
        <div className={`${mainClass}_Info` }>
            {
                props.info.map((info: any, infoIndex: number) => {
                    return <div key={infoIndex} className={props.blockName.isCollege ? `${mainClass}_Info_Line` : `${mainClass}_Info_Line`}>
                            {
                                props.exams.filter((exam: any) => exam.id === info.subject).map((exam: any, index: number) => {
                                    return <div key={index} className={`${mainClass}_Info_Line_Key`}>{exam.name}</div>
                                })
                            }
                            {!props.blockName.isCollege && <div className={`${mainClass}_Info_Line_Value`}>{info.score}</div>}
                        </div>
                })}
            {!Array.isArray(props.info) && typeof props.info.text !== "undefined" &&
            <div className={`${mainClass}_Info_Text`}>{props.info.text}</div>}
        </div>
    </div>
}

function InfoBlock2(props: IInfoBlockProps) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block"

    return <div className={`DirectionInfo_InfoBlocks_Block ${mainClassWithSize}`}>
        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClass}_Statistics`}>
        <div className={`${mainClass}_Name`}>{props.blockName}</div>
        <div className={`${mainClass}_Info`}>
            {
                props.info.map((info: any, index: number) => {
                        return <div key={index} className={`${mainClass}_Info_Line`}>
                            <div className={`${mainClass}_Info_Line_Key`}>На {info.year} год</div>
                            <div className={`${mainClass}_Info_Line_Value`}>{info.quantity}</div>
                        </div>
                })}
            {!Array.isArray(props.info) && typeof props.info.text !== "undefined" &&
            <div className={`${mainClass}_Info_Text`}>{props.info.text}</div>}
        </div>
        </div>
    </div>
}

function InfoBlock3(props: IInfoBlockProps) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block"
    const form = props.form

    function priceString(price: number){
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ₽';
    }

    return <div className={`DirectionInfo_InfoBlocks_Block ${mainClassWithSize}`}>
        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClass}_Statistics`}>
        <div className={`${mainClass}_Name`}>{props.blockName}</div>
        <div className={`${mainClass}_Info`}>
            {
                props.info.map((info: any, index: number) => {
                        for (let i = 0; i<props.form.length; i++) {
                            if (form[i].id === info.training_form) {
                                return <div key={i} className={`${mainClass}_Info_Line`}>
                                {
                                    props.type.filter((type: any) => form[i].type_training_form === type.id).map((type: any, index: number) => {
                                            return <div key={index}
                                                        className={`${mainClass}_Info_Line_Key`}>
                                                {type.name}  {form[i].postfix ? '–' : ''} {form[i].postfix}
                                            </div>
                                    })
                                }
                                <div className={`${mainClass}_Info_Line_Value`}>{priceString(info.price)}</div>
                            </div>
                            }
                        }
                        return <div key={index}/>
                })}
            {!Array.isArray(props.info) && typeof props.info.text !== "undefined" &&
            <div className={`${mainClass}_Info_Text`}>{props.info.text}</div>}
        </div>
        </div>
    </div>
}


function InfoBlock4(props: IInfoBlockProps) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block"
    return <div className={`DirectionInfo_InfoBlocks_Block ${mainClassWithSize}`}>
        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClass}_Statistics`}>
        <div className={`${mainClass}_Name`}>{props.blockName}</div>
        <div className={`${mainClass}_Info`}>
            {
                props.info.map((info: any, index: number) => {
                        return <div key={index} className={`${mainClass}_Info_Line`}>
                            {
                            props.form.filter((form: any) => form.id === info.training_form).map((form: any, index: number) => {
                                    return <div key={index} className={`${mainClass}_Info_Line_Key`}>{form.name}</div>
                            })}
                            <div className={`${mainClass}_Info_Line_Value`}>{info.quantity}</div>
                        </div>

                })}
        </div>
        </div>
    </div>
}

function InfoBlock5(props: IInfoBlockProps) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block"

    function yearNaming(years: number) {
        if (years === 1) return 'год'
        else if (years > 1 && years < 5) return 'года'
        return 'лет'
    }
    return <div className={`DirectionInfo_InfoBlocks_Block ${mainClassWithSize}`}>
        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClass}_Statistics`}>
        <div className={`${mainClass}_Name`}>{props.blockName}</div>
        <div className={`${mainClass}_Info`}>
            {
                props.form.filter((info: any, index: number) => {
                    for(let i = index + 1; i < props.form.length; i++){
                        const nextFrom = props.form[i]
                        if (info.type_training_form === nextFrom.type_training_form
                            && info.yead_score === nextFrom.yead_score
                            && info.postfix === nextFrom.postfix) return false
                    }
                    return true;
                } ).map((info: any, index: number) => {
                        return <div key={index} className={`${mainClass}_Info_Line`}>
                            {
                                props.type.filter((type: any) => type.id === info.type_training_form).map((type: any, index: number) => {
                                        return <div key={index} className={`${mainClass}_Info_Line_Key`}>
                                            {type.name} {info.postfix ? '–' : ''} {info.postfix}
                                    </div>
                                })}
                            <div className={`${mainClass}_Info_Line_Value`}>{info.yead_score} {yearNaming(info.yead_score)}</div>
                        </div>
                })}
        </div>
        </div>
    </div>
}

function InfoBlockText(props: IInfoBlockProps) {
    const mainClassWithSize = "DirectionInfo_InfoBlocks_Block_" + InfoBlockSize[props.size].toString()
    const mainClass = "DirectionInfo_InfoBlocks_Block"
    return <div className={`DirectionInfo_InfoBlocks_Block ${mainClassWithSize}`}>
        <div className={`DirectionInfo_InfoBlocks_Block_BigTop`}>
        <div className={`${mainClass}_Image`}><img src={require(`./Static/` + props.icon + `.svg`).default} alt=''/></div>
        <div className={`${mainClassWithSize}_Name`}>{props.blockName}</div>
        </div>
        <div className={`${mainClassWithSize}_Info`}>
            <div className={`${mainClass}_Info_Text`}>{props.info.description}</div>
        </div>
    </div>
}

