/**
 * Description: Пункт меню и якорь
 */
export const topMenuConfigFaculty: [string, string, boolean][] = [
    ['О факультете', '#about', true],
    ['Направления', '#directions', true],
    ['Новости', '#news', false],
    ['Жизнь студентов', '#students', false],
    ['Отзывы', '#reviews', false],
    ['Другие факультеты', '#others', true],
]

export const topMenuConfigDirection: [string, string, boolean][] = [
    ['О направлении', '#direction', true],
    ['Будущие профессии', '#profession', false],
    ['Практики', '#practice', false],
    ['Дисциплины', '#discipline', false],
    ['Другие факультеты', '#others', true]
]

