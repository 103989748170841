import {IFacultyStyleConfig} from "../../Config/faculties.config";

import './directions-cloud.style.sass'
import React, {useState} from "react";


interface IDirectionsCloudProps {
    style: IFacultyStyleConfig
    subjects: [ISubject]
}

interface ISubject{
    id: number
    name: string
    direction: number
}

export default function DirectionsCloud(props: IDirectionsCloudProps) {
    const [showAllSubjects, setShowAllSubjects] = useState(false)
    const limitSubjects = 5
    const resultSubjectsArray = props.subjects.filter((subject: ISubject) => subject.name.length < 40)
    const resultPaginationSubjectsArray = resultSubjectsArray.filter((subject: ISubject, index: number) => showAllSubjects ? true : index < limitSubjects)

    if (props.subjects.length > 0) {
        return <div className="DirectionsCloud" id="discipline">
            <div className="DirectionsCloud_HeadText">Дисциплины</div>
            <div className="DirectionsCloud_Cloud">
                <div className="DirectionsCloud_Cloud_Mobile">
                    {resultPaginationSubjectsArray.map((subject: ISubject, index: number) => {
                        return <div key={index} className={"DirectionsCloud_Cloud_Mobile_Tag"}>
                            <div className={"DirectionsCloud_Cloud_Mobile_Tag_Block"}>{subject.name}</div>
                        </div>
                    })}
                    <div className="DirectionsCloud_Cloud_Mobile_Button" onClick={() => setShowAllSubjects(true)}
                         style={{
                             display: showAllSubjects ? "none" : "table",
                             backgroundColor: props.style.colors.baseColor
                         }}>
                        <div className="DirectionsCloud_Cloud_Mobile_Button_Text">
                            Показать еще {resultSubjectsArray.length - limitSubjects}
                        </div>
                    </div>
                </div>
                <div className="DirectionsCloud_Cloud_Desktop">
                    {resultSubjectsArray.map((subject: ISubject, index: number) => {
                        return <div key={index} className={"DirectionsCloud_Cloud_Tag"}>{subject.name}</div>
                    })}
                </div>
            </div>
            <div className="DirectionsCloud_Circle_1" style={{background: props.style.colors.styleColor[2]}}/>
            <div className="DirectionsCloud_Circle_2" style={{background: props.style.colors.styleColor[3]}}/>
        </div>
    }
    else return <div />
}
