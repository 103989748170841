export const GET_FACULTY = 'GET_FACULTY';
export const GET_DIRECTION = 'GET_DIRECTION';
export const GET_NEWS = 'GET_NEWS';
export const GET_REASONS = 'GET_REASONS';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_REVIEWS = 'GET_REVIEWS';
export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_PROFESSIONS = 'GET_PROFESSIONS';
export const GET_PRACTICES = 'GET_PRACTICES';
export const GET_EXAM = 'GET_EXAM';
export const GET_SCORES = 'GET_SCORES';
export const GET_PRICES = 'GET_PRICES';
export const GET_TYPES = 'GET_TYPES';
export const GET_DATES = 'GET_DATES';
export const GET_QUOTAS = 'GET_QUOTAS';
export const GET_FORMS = 'GET_FORMS';
export const GET_EXAMSCORES = 'GET_EXAMSCORES'
export const GET_NEWS_TYPE = 'GET_NEWS_TYPE';
export const GET_SUBJECTS = 'GET_SUBJECTS'
export const SET_FACULTY_NAME = 'SET_FACULTY_NAME'



export const ERRORS = 'ERRORS';
