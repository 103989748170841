export interface IReviewItem {
    fio: string
    img: string
    spec: string
    year: number
    comment: string
    date: string
}

export interface IEventItem {
    title: string
    date: string
    description: string
    img: string
}


export interface IFacultiesConfig {
    [string: string]: IFacultyStyleConfig
}

export interface IFacultiesColors {
    baseColor: string
    textColor: string
    directionsColor: string[]
    styleColor: string[]
}

export interface IFacultyStyleConfig {
    id: number,
    name: string,
    colors: any
}


export const facultiesConfig: IFacultiesConfig = {
    default: {
        id: 0,
        name: 'Default',
        colors: {
            baseColor: "#7631E2",
            gradientColor: ["#19605e", "#1e9894"],
            textColor: "#FFFFFF",
            directionsColor: ["#19605e", "#1e9894", "#19605e", "#1e9894", "#19605e", "#1e9894"],
            ss: ["#6654FF", "#792BDD"],
            styleColor: ["#FFD5AF", "#F5841F", "#A298E2", "#F5841F"],
            back: "#C8BDFF"
        }
    },
    ISIT: {
        id: 3,
        name: 'ИСиТ',
        colors: {
            baseColor: "#7631E2",
            header: 'linear-gradient(118.39deg, #5671FF -7.2%, #6654FF 25.97%, #792BDD 55.11%)',
            gradientColor: ["#5671FF", "#6654FF", "#792BDD"],
            textColor: "#FFFFFF",
            directionsColor: ["#DBADFF", "#CADAFB", "#CCD4FF", "#CCBBFB", "#E6DDFF", "#C29DFF"],
            styleColor: ["#FFD5AF", "#F5841F", "#A298E2", "#F5841F"],
            ss: ["#6654FF", "#792BDD"],
            footer: "#513FFF",
            back: "#C8BDFF",
            admission: ["#FFE2C8FC", "#FF9D46", "#F5841F"]
        }
    },
    RTS: {
        id: 1,
        name: 'РТС',
        colors: {
            baseColor: "#FF8585",
            header: 'linear-gradient(105.5deg, #FF8585 -13.09%, #FF4646 60.33%)',
            gradientColor: ["#FF8585", "#FF4646", "#FF4646"],
            textColor: "#FFFFFF",
            directionsColor: ["#FFADAD", "#FBCAD6", "#FFCCCC", "#FBCEBB", "#FFDDDD", "#FF9D9D"],
            ss: ["#FF8585", "#FF4646"],
            styleColor: ["#FFF5C0", "#FFB396", "#FF8585", "#FFB396"],
            footer: "#FF4646",
            back: "#FFBDBD",
            admission: ["#FFB396", "#FF4646", "#FF8585"]

        }
    },
    IKSS: {
        id: 2,
        name: 'ИКСС',
        colors: {
            baseColor: "#E7270D",
            header: 'linear-gradient(105.5deg, #E7270D -13.09%, #FF7A00 60.33%)',
            gradientColor: ["#E7270D", "#E7270D", "#FF7A00"],
            textColor: "#FFFFFF",
            directionsColor: ["#FFCFAD", "#FBDBCA", "#FFD8CC", "#FBBFBB", "#FFDDDD", "#FFB59D"],
            ss: ["#E7270D", "#FF7A00"],
            styleColor: ["#FFB396", "#F4D15B", "#F4D15B", "#F35C00"],
            footer: "#FF7A01",
            back: "#FFD5AF",
            admission: ["#FFB39679", "#FFB396", "#FF7A01"]
        }
    },
    TSEUBI: {
        id: 6,
        name: 'ЦЭУБИ',
        colors: {
            baseColor: "#9DC4FF",
            header: 'linear-gradient(105.5deg, #4A47A3 -13.09%, #4A47A3 60.33%)',
            gradientColor: ["#4A47A3", "#4A47A3", "#4A47A3"],
            textColor: "#FFFFFF",
            directionsColor: ["#ADCEFF", "#CAD8FB", "#CCDAFF", "#BBC9FB", "#DDE9FF", "#9DC4FF"],
            ss: ["#4A47A3", "#4A47A3"],
            styleColor: ["#A7C5EB", "#709FB0", "#413C69", "#A7C5EB"],
            footer: "#4A47A3",
            back: "#BDCCFF",
            admission: ["#A7C5EB6A", "#413C69", "#4A47A3"]
        }
    },
    SCT: {
        id: 4,
        name: 'СЦТ',
        colors: {
            baseColor: "#61C1C0",
            header: 'linear-gradient(105.5deg, #FFF54D -13.09%, #B8DD7D 13.34%, #51BBBA 60.33%)',
            gradientColor: ["#FFF54D", "#B8DD7D", "#51BBBA"],
            textColor: "#FFFFFF",
            directionsColor: ["#62C2C1", "#FBF8BB", "#CAFBE3", "#D3FFD2", "#DCFFCC", "#9DFFB9"],
            ss: ["#61C1C0", "#B8DE8D"],
            styleColor: ["#61C1C0", "#B8DE8D", "#F4D15B", "#61C1C0"],
            footer: "#61CB8B",
            back: "#FFF1C1",
            admission: ["#6CD79779", "#97CD96", "#61C1C0"]
        }
    },
    FP: {
        id: 5,
        name: 'ФП',
        colors: {
            baseColor: "#C1C1C1",
            header: 'linear-gradient(105.5deg, #3C4245 -13.09%, #5F6769 60.33%)',
            gradientColor: ["#3C4245", "#3C4245", "#5F6769"],
            textColor: "#FFFFFF",
            directionsColor: ["#D2D2D2", "#BABABA", "#6B6B6B", "#D2D2D2", "#A5A5A5", "#A6A6A6"],
            ss: ["#3C4245", "#5F6769"],
            styleColor: ["#DCD4CC", "#7C7C7C", "#3C4245", "#DCD4CC"],
            footer: "#7C7C7C",
            back: "#DCD4CC",
            admission: ["#B4B4B4B3", "#3C4245", "#7C7C7C"]
        }
    },
    SPBKT: {
        id: 7,
        name: 'СПбКТ',
        colors: {
            baseColor: "#BCB4FC",
            header: 'linear-gradient(105.5deg, #7C6CE4 -13.09%, #4DCBF0 60.33%)',
            gradientColor: ["#7C6CE4", "#7C6CE4", "#4DCBF0"],
            textColor: "#FFFFFF",
            directionsColor: ["#ADDDFF", "#9DD6FF", "#CAE6FB", "#BBDCFB", "#BFDAFF", "#CCEDFF"],
            ss: ["#7C6CE4", "#4DCBF0"],
            styleColor: ["#9DC4FF", "#47B2FF", "#4DCBF0", "#9DC4FF"],
            footer: "#7C6CE4",
            back: "#47B2FF",
            admission: ["#D4E4FCFC", "#7C6CE4", "#4DCBF0"]
        }
    },
    VUC: {
        id: 8,
        name: 'ВУЦ',
        colors: {
            baseColor: "#8ACCB1",
            header: "linear-gradient(105.5deg, #091914 -13.09%, #33BD85 60.33%)",
            gradientColor: ["#091914", "#091914", "#33BD85"],
            textColor: "#FFFFFF",
            directionsColor: ["#97CD96", "#7FD1A0", "#95D2AF", "#5D8A81", "#8ACCB1", "#A6EBCA"],
            ss: ["#1b4b39", "#33BD85"],
            styleColor: ["#8EFCBA", "#D0ECDC", "#D0ECDC", "#5FB3C6"],
            footer: "#016852",
            back: "#8BC294",
            admission: ["#D0ECDC81", "#016852", "#D0ECDC"]
        }
    }
}
