import {IFacultyStyleConfig} from "../../Config/faculties.config";
import ChangeDate from "../../Logic/ChangeDate";

import ModalWindow from '../ModalWindow/ModalWindow.component'
import './news.style.sass'
import {defaultPicture} from "../../Config/DefaultPicture";
import React, {useState} from "react";

interface INewsProps {
    style: IFacultyStyleConfig
    news: any
    current_faculty: number
    type: any
}
enum NewsBlockType {
    small,
    middle,
    big
}


export default function News(props: INewsProps) {
    let arr: any[] = []
    let count = 0
    props.news.forEach((a: any) => {
        props.type.forEach((type: any) => {
            if (a.type === type.id) {
                if ((type.name_type === "3 приоритет") && (count<= 5)) {
                    arr[count] = a
                    count++
                }
                if ((type.name_type === "2 приоритет")) {
                    arr[6] = a
                }
                if ((type.name_type === "1 приоритет")) {
                    arr[7] = a
                }
            }
        })
    })
    if (props.news) {
        return <div className="BackNews">
            <div className="News">
                <div className="News_Header" id="news">Новости факультета</div>
                <div className="News_Area">
                    {
                        arr.map((a: any, i: number) => {
                                if (i < 6) {
                                    return <NewsBlock i={i} key={i} type={NewsBlockType.small} info={a} size='s'
                                                      style={props.style.colors}/>
                                }

                                if (i === 6) {
                                    return <NewsBlock key={i} type={NewsBlockType.middle} i={i} info={a} size='s'
                                                      style={props.style.colors}/>
                                }
                                if (i === 7) {
                                    return <NewsBlock key={i} type={NewsBlockType.big} i={i} info={a} size='s'
                                                      style={props.style.colors}/>
                                }
                                else return <div key={i}/>
                        })
                    }

                </div>
            </div>
            <svg className="News_BackPic" width="150%" height="100%" preserveAspectRatio="none" viewBox="0 0 1441 859"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1243.08 756.712L1514.33 527.719L1601.68 1.44159L1228.59 130.472C1212.11 136.172 1194.39 137.275 1177.33 133.663L698.585 32.2991C671.375 26.538 643.182 27.3616 616.354 34.7016L-203.577 259.035V724.807L-107.661 827.372C-78.4098 858.65 -32.0205 866.859 6.18726 847.517L352.376 672.271C396.778 649.794 447.407 642.762 496.253 652.289L1152.44 780.268C1184.66 786.552 1217.99 777.889 1243.08 756.712Z"
                    stroke={props.style.colors.back} strokeDasharray="15 15"/>
            </svg>

        </div>
    } else
        return <div/>
}


interface INewsBlockProps {
    info: any
    type: NewsBlockType
    i: number
    style: any
    size: string
}


export function NewsBlock(props: INewsBlockProps) {
    const mainClass = 'News_Block'
    const mainClassSize = `${mainClass}_${NewsBlockType[props.type].toString()}`
    const mainClassSizeKey = `${mainClassSize}_${props.i + 1}`
    const [showModule, setShowModule] = useState(false)
    return <>
    <div style={{backgroundImage: props.info?.link_picture[0] ? `url(${props.info.link_picture[0][props.size + '_link']})`: `url(${defaultPicture})`}}
                className={`${mainClass} ${mainClassSize} ${mainClassSizeKey}`}>
        <div className='News_Block_main' onClick={() => {
            setShowModule(true);
            document.body.style.overflowY = 'hidden';
        }}>
            <div className='News_Block_info'>
                <div className='News_Block_info_name'>{props.info.name}</div>
                <div className='News_Block_info_date'>{ChangeDate(props.info.date_news)}</div>
            </div>
            <div className='News_Block_main_filter'/>
        </div>
    </div>
        {ModalWindow(showModule, setShowModule, props.info, props.style)}
    </>
}
