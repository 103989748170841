import React, {useEffect} from 'react';


import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import MainLayout from "../Layouts/Main/main.layout";
import generateConfig from "../Logic/generateConfig";
import './App.styles.sass'
import DirectionLayout from "../Layouts/Directions/direction.layout";
import {setFacultyName} from "../Store/actions/facultiesAction";
import {useDispatch} from "react-redux";


export default function App() {
    const [facultyNameFromDomain, setFacultyNameFromDomain] = React.useState<null | string>(null);
    const dispatch = useDispatch()

    useEffect(() => {
        const splitHostname = window.location.hostname.split('.')
        const facultyName = splitHostname[0]
        const config = generateConfig(facultyName)
        if (config.id === 0) {
            return;
        }
        setFacultyNameFromDomain(facultyName)
        dispatch(setFacultyName(facultyName));
    }, [dispatch])


    const routesWithDomain = <>
        <Route exact path="/"
               render={() => <MainLayout
                   config={generateConfig(facultyNameFromDomain)}/>}
        />
        <Route exact path="/:direction" render={() =>
            <DirectionLayout config={generateConfig(facultyNameFromDomain)}/>}
        />
    </>

    const routesWithoutDomain = <>
        <Route exact path="/:faculty"
               render={props =>
               {
                   dispatch(setFacultyName(props.match.params.faculty))
                   return <MainLayout config={generateConfig(props.match.params.faculty)}/>
               }
               }
        />
        <Route exact path="/:faculty/:direction" render={props =>
        {
            dispatch(setFacultyName(props.match.params.faculty))
            return <DirectionLayout config={generateConfig(props.match.params.faculty)}/>
        }
        }
        />
    </>

    const localBuild = process.env.REACT_APP_BUILD === 'local'

    return (
        <div className="App">
            <Router>
                <Switch>
                    {localBuild ? routesWithoutDomain : facultyNameFromDomain && routesWithDomain}
                </Switch>
            </Router>

        </div>
    );
}
