const StagesCoords = [
    {
        id: 0,
        x: '-101',
        y: '-475',
        rect_coords_x: '141',
        rect_coords_y: '1250'
    },
    {
        id: 1,
        x: '-171',
        y: '-500',
        rect_coords_x: '211',
        rect_coords_y: '1250'
    },
    {
        id: 2,
        x: '-241',
        y: '-525',
        rect_coords_x: '281',
        rect_coords_y: '1270'
    },
    {
        id: 3,
        x: '-311',
        y: '-550',
        rect_coords_x: '351',
        rect_coords_y: '1290'
    },
    {
        id: 4,
        x: '-381',
        y: '-575',
        rect_coords_x: '421',
        rect_coords_y: '1320'
    },
    {
        id: 5,
        x: '-451',
        y: '-595',
        rect_coords_x: '491',
        rect_coords_y: '1340'
    },
    {
        id: 6,
        x: '-521',
        y: '-595',
        rect_coords_x: '561',
        rect_coords_y: '1340'
    },
    {
        id: 7,
        x: '-591',
        y: '-575',
        rect_coords_x: '631',
        rect_coords_y: '1320'
    },
    {
        id: 8,
        x: '-661',
        y: '-550',
        rect_coords_x: '701',
        rect_coords_y: '1300'
    },
    {
        id: 9,
        x: '-730',
        y: '-520',
        rect_coords_x: '771',
        rect_coords_y: '1260'
    },
    {
        id: 10,
        x: '-800',
        y: '-495',
        rect_coords_x: '841',
        rect_coords_y: '1250'
    },
    {
        id: 11,
        x: '-870',
        y: '-470',
        rect_coords_x: '911',
        rect_coords_y: '1215'
    },
    {
        id: 12,
        x: '-940',
        y: '-445',
        rect_coords_x: '981',
        rect_coords_y: '1200'
    },
    {
        id: 13,
        x: '-1010',
        y: '-415',
        rect_coords_x: '1051',
        rect_coords_y: '1165'
    },
    {
        id: 14,
        x: '-1080',
        y: '-385',
        rect_coords_x: '1121',
        rect_coords_y: '1160'
    },
    {
        id: 15,
        x: '-1150',
        y: '-355',
        rect_coords_x: '1191',
        rect_coords_y: '1110'
    },
    {
        id: 16,
        x: '-1220',
        y: '-330',
        rect_coords_x: '1261',
        rect_coords_y: '1110'
    },
    {
        id: 17,
        x: '-1290',
        y: '-305',
        rect_coords_x: '1331',
        rect_coords_y: '1050'
    },
    {
        id: 18,
        x: '-1360',
        y: '-280',
        rect_coords_x: '1401',
        rect_coords_y: '1050'
    },
    {
        id: 19,
        x: '-1430',
        y: '-252',
        rect_coords_x: '1471',
        rect_coords_y: '1010'
    },
]
export default StagesCoords;
