import {IFacultyStyleConfig} from "../../Config/faculties.config";
import React from "react";

import SutLogo from './Static/sut_logo.svg'

import Crysto from './Static/Crysto_logo.svg'
import './footer.style.sass'
import {useSelector} from "react-redux";

interface IFooterProps {
    data: any
    style: IFacultyStyleConfig
    faculties: any
}


function createPathString(currentFaculty: string, faculty: string): string {
    if (process.env.REACT_APP_BUILD === 'local') return `/${faculty}`
    return window.location.origin.replace(currentFaculty, faculty.toLowerCase())
}


export default function Footer(props: IFooterProps) {
    const {facultyName} = useSelector((state: any) => state.faculty)

    if (typeof props.data.link_logo != 'undefined') {
        return <div className="Footer" id="others" style={{backgroundColor: props.style.colors.footer}}>
            <div className="Footer_Info_Circle_3" style={{backgroundColor: props.style.colors.styleColor[3]}}/>
            <div className="Footer_Info">
                <div className="Footer_Info_Circle_1" style={{backgroundColor: props.style.colors.styleColor[0]}}/>
                <div className="Footer_Info_Circle_2" style={{backgroundColor: props.style.colors.styleColor[2]}}/>
                <div className="Footer_Info_Circle_4" style={{backgroundColor: 'white'}}/>


                <div className="Footer_Info_Data">

                    <img className="Footer_Info_Data_FacultyLogo"
                         src={`${props.data.link_logo.orig_link}`} alt=''/>
                    <div className="Footer_Info_Data_Bonch">Санкт-Петербургский государственный университет
                        телекоммуникаций им. проф. М.А.Бонч-Бруевича
                    </div>
                    <div className="Footer_Info_Data_Address">Россия, 193232, Санкт-Петербург,пр. Большевиков д.22,
                        к.1
                    </div>
                    <div className="Footer_Info_Data_BonchLogo"><img src={SutLogo} alt=' '/></div>
                    <div className="Footer_Info_Data_Copyright">©2022</div>
                </div>
            </div>
            <div className="Footer_Link">
                {
                    Object.keys(props.faculties)
                        .filter(key => key !== 'default')
                        .map((key) => {
                            return <a
                                href={createPathString(facultyName, key)}
                                key={key}
                                className="Footer_Link_Faculty">
                                {props.faculties[key].name}
                            </a>
                    })}
            </div>
            <div className="Footer_Crysto" >
                <div>Сделано в</div>
                <a target="_blank" href="https://crysto.ru" rel="noreferrer">
                <img src={Crysto} alt=''/>
                </a>
        </div>
        </div>
    } else
        return <div/>
}
