import {
    GET_EXAM,
    GET_EXAMSCORES,
    GET_FORMS,
    GET_PRACTICES,
    GET_PRICES,
    GET_PROFESSIONS,
    GET_PROGRAM,
    GET_QUOTAS,
    GET_SCORES,
    GET_TYPES,
    GET_SUBJECTS,
} from "../types";
import update from "react-addons-update";

const initialState = {
    program: [],
    practices: [],
    professions: [],
    exams: [],
    scores: [],
    prices: [],
    types: [],
    quotas: [],
    forms: [],
    examScores: [],
    subjects: [],
    isFetchingDirection: [false,false,false,false,false,false,false,false],
}

export default function DirectionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROGRAM:
            return update(state, {
                program: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    0:{
                        $set: true
                    }
                }
            })
        case GET_PRACTICES:
            return update(state, {
                practices: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    1:{
                        $set: true
                    }
                }
            })
        case GET_PROFESSIONS:
            return update(state, {
                professions: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    2:{
                        $set: true
                    }
                }
            })
        case GET_EXAM:
            return update(state, {
                exams: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    3:{
                        $set: true
                    }
                }
            })
        case GET_SCORES:
            return update(state, {
                scores: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    4:{
                        $set: true
                    }
                }
            })
        case GET_PRICES:
            return update(state, {
                prices: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    5:{
                        $set: true
                    }
                }
            })
        case GET_TYPES:
            return {
                ...state,
                types: action.payload
            }
        case GET_QUOTAS:
            return update(state, {
                quotas: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    6:{
                        $set: true
                    }
                }
            })
        case GET_FORMS:
            return {
                ...state,
                forms: action.payload
            }
        case GET_EXAMSCORES:
            return update(state, {
                examScores: {
                    $set: action.payload
                },
                isFetchingDirection: {
                    7:{
                        $set: true
                    }
                }
            })
        case GET_SUBJECTS:
            return {
                ...state,
                subjects: action.payload
            }
        default:
            return state
    }
}
