
import {IFacultyStyleConfig} from "../../Config/faculties.config";
import ModalWindow from '../ModalWindowEvents/ModalWindowEvent.component'

import './sovet.style.sass'
import React, {useState} from "react";
import {defaultPicture} from "../../Config/DefaultPicture";

interface ISovetProps {
    style: IFacultyStyleConfig
    data: any
    events: any
}

const seasons: [string, string, string, string] = [
    'Зима', 'Весна', 'Лето', 'Осень'
]

export default function Sovet(props: ISovetProps) {
    if (props.events) {
        return <div className="Sovet">
            <div className="Sovet_Background">
                <svg width="100%" viewBox="0 0 1113 892" preserveAspectRatio="none" fill="none"
                     xlinkHref="data:image/png" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M227.112 8.59294L-12.5068 88.4458V789.57L164.74 735.185C198.095 724.951 233.799 725.281 266.96 736.131L635.788 856.806C662.141 865.428 690.541 865.532 716.957 857.102L1245.93 688.296V179.332L1053.98 114.494C1038.86 109.389 1022.49 109.389 1007.37 114.494L867.979 161.578C833.831 173.112 796.893 173.437 762.547 162.506L277.857 8.24907C261.332 2.98977 243.564 3.11018 227.112 8.59294Z"
                        fill="url(#paint_linear)"/>
                    <defs>
                        <linearGradient id="paint_linear" x1="-198.789" y1="59.8815" x2="625.984" y2="704.635"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor={props.style.colors.ss[0]}/>
                            <stop offset="0.483852" stopColor={props.style.colors.ss[0]}/>
                            <stop offset="0.909034" stopColor={props.style.colors.ss[1]}/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="Sovet_Header_Block"><div className="Sovet_Header" id="students">Студенческий совет</div>
                <div className="Sovet_Description">{props.data.description_ss}</div></div>
            <div className="Sovet_MainBlock">
                <div className="Sovet_MainBlock_Photos"><SovetPhoto data={props.data.link_picture_ss}/></div>
                <div className="Sovet_MainBlock_Events"><SovetEvent events={props.events} style={props.style}/></div>
            </div>
        </div>
    } else
        return <div/>
}


function SovetPhoto(props: ISovetPhoto): any {
        return <div className="Sovet_MainBlock_Photos_Component"
                 style={{
                     backgroundImage: props.data ? `url(${props.data.m_link})`: `url(${defaultPicture})`,
                 }}
            />
}

interface ISovetEventProps {
    events: any
    style: any
}

interface ISovetPhoto {
    data: any
}

function SovetEvent(props: ISovetEventProps) {
    return <div className="Sovet_MainBlock_Events_Component">
        <div className="Sovet_MainBlock_Events_Component_Circle_1"
             style={{backgroundColor: props.style.colors.styleColor[1]}}/>
        <div className="Sovet_MainBlock_Events_Component_Circle_2"
             style={{backgroundColor: props.style.colors.styleColor[0]}}/>
        <div className="Sovet_MainBlock_Events_Component_Header">Мероприятия на факультете</div>
        <div className="Sovet_MainBlock_Events_Component_Events">
            {props.events.map((event: any, index: number) => {
                return <SovetEventBlock key={index} event={event} style={props.style.colors}/>
            })}
        </div>
    </div>
}

function SovetEventBlock(props: any) {
    const [showModule, setShowModule] = useState(false)
    let current_season = seasons[Number(props.event.seasons)-1]
    return <div className="Sovet_MainBlock_Events_Component_Event">
        <div className="Sovet_MainBlock_Events_Component_Event_Block" onClick={() => {
            document.body.style.overflowY = 'hidden';
            setShowModule(true);
        }}>
            <div className="Sovet_MainBlock_Events_Component_Event_Title">
                <div className="Sovet_MainBlock_Events_Component_Event_Title_Name">{props.event.name}</div>
                <div
                    className="Sovet_MainBlock_Events_Component_Event_Title_Date">{current_season}</div>
            </div>
            <div className="Sovet_MainBlock_Events_Component_Event_Description">
                {props.event.short_description}
            </div>
            <div className="Sovet_MainBlock_Events_Component_Event_Img"
                 style={{backgroundImage: props.event.link_picture.length ? `url(${props.event.link_picture[0].xs_link})` : `url(${defaultPicture})`}}/>
        </div>
        {ModalWindow(showModule, setShowModule, props.event, props.style, current_season)}
    </div>
}
