import {facultiesConfig, IFacultyStyleConfig} from "../../Config/faculties.config";
import './topBlockPhoto.styles.sass'
import React from "react";

import vk from './Static/vk.svg'

interface ITopBlockPhotoProps {
    style: IFacultyStyleConfig
    data: any
}


const styles: IFacultyStyleConfig = {...facultiesConfig.default}


export default function TopBlockPhoto(props: ITopBlockPhotoProps) {
    //Assign dynamical style properties globaly to all component's subcomponentes
    Object.assign(styles, props.style)

    const BlockStyle = {
        background: styles.colors.header
    }

    if (typeof props.data.link_picture != 'undefined') {
        return <div className="TopBlockPhoto">


            <div className="TopBlockPhoto_image"
                 style={{backgroundImage: `url(${props.data.link_picture.m_link})`}}>

                <div className="TopBlockPhoto_Filter" style={BlockStyle}/>
                <div className="TopBlockPhoto_HeadText">
                    <div><img className="TopBlockPhoto_HeadText_Logo"
                              src={`${props.data.link_logo.orig_link}`} alt=''/></div>
                    <p>Факультет {props.data.short_name} СПбГУТ им.проф. М.А.Бонч-Бруевича</p>
                    <div className="TopBlockPhoto_HeadText_Link">
                        {(props.data.vk_link !== 'NULL') ?
                            <a target="_blank" rel="noreferrer" href={props.data.vk_link}><img src={vk} alt=""/></a> : <div/>}
                    </div>
                </div>
            </div>
            <svg width="0" height="0">
                <defs>
                    <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
                        <path
                            d="M0,0.729 c0,0,0.043,0.03,0.061,0.041 c0.009,0.005,0.063,0.051,0.085,0.051 c0.041,0.001,0.108,-0.077,0.149,-0.078 c0.053,0,0.081,0.043,0.163,0.095 c0.077,0.048,0.203,0.152,0.255,0.161 c0.033,0.005,0.085,-0.057,0.15,-0.09 c0.054,-0.027,0.098,-0.063,0.101,-0.067 c0.013,-0.016,-0.027,-0.036,-0.028,-0.047 c-0.002,-0.03,0.064,-0.073,0.064,-0.073 V0 H0 V0.729"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
    } else return <div/>
}
