import {facultiesConfig, IFacultyStyleConfig} from "../../Config/faculties.config";
import React from "react";
import './admission.sass'
import StagesCoords from "./StagesCoord";

interface IAdmissionProps {
    style: IFacultyStyleConfig
    steps: [IStage]
}

interface IStage {
    id: number,
    name_stage: string,
    date_stage: string,
    faculty: number
}

interface IStageCoords{
    id: number,
    x: string,
    y: string,
    rect_coords_x: string,
    rect_coords_y: string,
}

const styles: IFacultyStyleConfig = {...facultiesConfig.default}

function ChangeDateDots(a: string) {
    let new_a = a.split('-')
    return new_a[2] + '.' + new_a[1] + '.' + new_a[0][2] + new_a[0][3]
}

export default function Admission(props: IAdmissionProps) {
        Object.assign(styles, props.style)
        const pass = Math.floor((20 / props.steps.length));
        const remainder = (20 % props.steps.length) / 2;

        function filteredStages(stage: IStageCoords) {
            if ((stage.id >= remainder)) {
                if ((stage.id % pass) === 0) {
                    return stage
                }
            }
        }

        const result = StagesCoords.filter(filteredStages)
        let direction = 1
        if (props.steps.length > 0) {
        return <div className="Admission">
            <div className="Admission_Header">
                <div>Процесс поступления</div>
            </div>
            <div className="Admission_Block">

                <svg className="Admission_Block_Desktop" width="115%" height="100%" viewBox="0 850 2152 803" fill="none"
                     xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                    <g mask="url(#mask0_204_1046)">
                        <path
                            d="M1960.78 1114.73L2249.02 994.085V-90L-166 -68.2093V994.085L-17.0984 1065.62C-4.50317 1071.68 -3.94995 1089.41 -16.1433 1096.23L-81.4231 1132.77C-91.1018 1138.19 -89.925 1152.47 -79.4902 1156.23L464.522 1352.29C508.3 1368.07 556.304 1367.5 599.696 1350.69L1437.92 1025.95C1490.69 1005.5 1549.32 1006.29 1601.52 1028.14L1808.4 1114.73C1857.14 1135.13 1912.03 1135.13 1960.78 1114.73Z"
                            fill="url(#paint0_linear_204_1046)" fillOpacity="0.99"/>
                        <path
                            d="M2249.02 994.085L1960.78 1114.73C1912.03 1135.13 1857.14 1135.13 1808.4 1114.73L1601.52 1028.14C1549.32 1006.29 1490.68 1005.5 1437.92 1025.95L599.695 1350.69C556.303 1367.5 508.299 1368.07 464.521 1352.29L-106.17 1146.62"
                            stroke={props.style.colors.admission[1]} strokeWidth="5.27043" strokeLinejoin="round"/>
                    </g>
                    {
                        props.steps.map((stage: IStage, index: number) => {
                            const coords_y = Number(result[index].rect_coords_y)
                            const coords_x = Number(result[index].rect_coords_x)
                            direction = -(direction)
                            return <svg x="0" y="0" width="500" height="500" viewBox=" 0 0 500 500" key={index}>
                                <svg x={coords_x + 30}
                                     y={direction > 0 ? coords_y + direction * 180 : coords_y + direction * 250}>
                                    <foreignObject className="a" width="100%" height="100%">
                                        <div className="Admission_Block_Desktop_Stage"
                                             style={{width: `${120 / props.steps.length}vw`}}>
                                            <div className="Admission_Block_Desktop_Stage_Number">{index + 1}</div>
                                            <div className="Admission_Block_Desktop_Stage_Info">
                                                <div
                                                    className="Admission_Block_Desktop_Stage_Name">{stage.name_stage}</div>
                                                <div
                                                    className="Admission_Block_Desktop_Stage_Date">
                                                    {ChangeDateDots(stage.date_stage)}
                                                </div>
                                            </div>
                                        </div>
                                    </foreignObject>
                                </svg>
                                <rect width="5.24143" height="362.318"
                                      transform={`matrix(1 0 0 ${direction} ${result[index].rect_coords_x} ${result[index].rect_coords_y})`}
                                      fill="url(#paint0_linear_204_1050)"/>

                                <ellipse cx={result[index].x} cy={result[index].y} rx="21" ry="21"
                                         transform="rotate(-180 21.1201 380.584)"
                                         fill={props.style.colors.admission[1]}/>
                                <ellipse cx={result[index].x} cy={result[index].y} rx="10.5142" ry="10.5083"
                                         transform="rotate(-180 21.1206 380.584)"
                                         fill={props.style.colors.admission[2]}/>
                                <defs>
                                    <linearGradient id="paint0_linear_204_1050" x1="2.62071" y1="0" x2="2.62071"
                                                    y2="362.318" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.33912" stopColor={props.style.colors.admission[1]}/>
                                        <stop offset="1" stopColor={props.style.colors.admission[1]} stopOpacity="0"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        })
                    }
                    <defs>
                        <linearGradient id="paint0_linear_204_1046" x1="1352.56" y1="439.356" x2="1351.09" y2="1515.91"
                                        gradientUnits="userSpaceOnUse">
                            <stop offset="0.0214119" stopColor="white" stopOpacity="0"/>
                            <stop offset="0.766204" stopColor={props.style.colors.admission[0]}/>
                        </linearGradient>
                    </defs>
                </svg>


                <div className="Admission_Block_Mobile_Stages">
                    {
                        props.steps.map((stage: any, index: number) => {
                            return <div key={index} className="Admission_Block_Mobile_Stage" style={
                                {
                                    'padding': index % 2 === 0 ? '0 0 0 60%' : '0 0 0 0',
                                    'textAlign': index % 2 === 0 ? 'left' : 'right'
                                }
                            }>
                                <div className="Admission_Block_Mobile_Stage_Name">{stage.name_stage}</div>
                                <div
                                    className="Admission_Block_Mobile_Stage_Date">{ChangeDateDots(stage.date_stage)}</div>
                            </div>
                        })
                    }
                </div>
                <div className="Admission_Block_Mobile">
                    <svg className="Admission_Block_Mobile_Svg" width="100%" height="100%" viewBox="0 0 27 482"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3163 4.30872L13.3163 468.207" stroke={props.style.colors.baseColor}
                              strokeWidth="0.915703" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div className="Admission_Block_Mobile_Circle">
                        {
                            props.steps.map((stage: any, index: number) => {
                                return <svg key={index} width="15" height="15" viewBox="0 0 15 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7.81113" r="6.63885" fill={props.style.colors.baseColor}
                                            stroke={props.style.colors.baseColor} strokeWidth="0.457852"/>
                                </svg>
                            })
                        }
                    </div>
                    <div>

                    </div>
                </div>
            </div>

        </div>
    }
    else return <div />
}
