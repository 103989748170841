
import {IFacultyStyleConfig} from "../../Config/faculties.config";

import './directions.style.sass'
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

interface IDirectionsProps {
    style: IFacultyStyleConfig
    directions: any
    current_faculty: number
}


interface IDirectionBlock {
    block: any
    color: string
    filter: string
}


export default function Directions(props: IDirectionsProps) {
    let current_color = -1
    if (props.directions) {
        return <div className="Directions" id={"directions"}>
            <div className="Directions_Header">Профили</div>

            <div className={props.directions.length > 3 ? "Directions_Blocks" : "Directions_Blocks_Min"}>
                {
                    props.directions.map((block: any, i: number) => {
                            if (current_color !== props.style.colors.directionsColor.length - 1) {
                                current_color++
                                return <DirectionBlock block={block} key={i.toString()}
                                                       color={props.style.colors.directionsColor[current_color]}
                                                       filter={String(current_color)}/>

                            } else {
                                current_color = 0
                                return <DirectionBlock block={block} key={i.toString()}
                                                       color={props.style.colors.directionsColor[current_color]}
                                                       filter={String(current_color) }/>
                            }

                    })
                }
            </div>
            <svg className="Directions_BackPic" width="1208" height={props.directions.length < 4 ? '0' : '1056'}
                 viewBox="0 0 1208 1056" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M-57.0783 1.03516C-191.687 210.426 58.3348 562.221 415.182 408.199C1292.4 29.572 1370.17 856.19 992.107 810.298C182.463 712.018 -579.141 762.221 122.348 1055.05"
                    stroke={props.style.colors.back} strokeDasharray="15 15"/>
            </svg>

        </div>
    } else
        return <div/>
}

function createPathString(directionId: number, facultyName: string): string{
    if (process.env.REACT_APP_BUILD === 'local') return `/${facultyName}/${directionId}`
    else  return `/${directionId}`
}

function DirectionBlock(props: IDirectionBlock ) {

    const style_block = {
        backgroundColor: props.color
    }
    const {facultyName} = useSelector((store: any) => store.faculty)

    const pathString = createPathString(props.block.id, facultyName)

    return <Link to={pathString} className="Directions_Blocks_Block" style={style_block}>
        <div className="Directions_Blocks_Block_Number" style={{textShadow: '3px 3px 4px ' + props.color}}>
            <div className="Directions_Blocks_Block_Number_Back">{props.block.kode_direction}</div>
            <div className="Directions_Blocks_Block_Mask">{props.block.kode_direction}</div>
            {props.block.kode_direction}</div>

        <div className="Directions_Blocks_Block_Name">{props.block.name_direction}</div>
        <div className="Directions_Blocks_Block_Description">{props.block.section}</div>
    </Link>
}
