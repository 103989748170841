
import {IFacultyStyleConfig} from "../../Config/faculties.config";

import './futureProf.style.sass'
import React from "react";

interface IFutureProfProps {
    style: IFacultyStyleConfig
    profession: any
    current_direction: number
}
export default function FutureProf(props: IFutureProfProps) {
    if (props.profession.length > 0) {
        return <div className="BackFuture">
            <div className="Future" id="profession">
                <div className="FutureProf_HeadText">Будущие профессии</div>


                <div className="FutureProf">
                    {
                        props.profession.filter((profession: any, index: number) => index < 3).map((block: any, index: number) => {
                                return <FutureProfBlock key={index} name={block.name} description={block.description}
                                                        image={block.link_picture}
                                                        color={props.style.colors.back}/>
                        })}
                </div>

            </div>
            <svg className="Future_BackPic" width="1441" height="100%" viewBox="0 0 1441 1136" fill="none" preserveAspectRatio="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1187.15 7.47984L1457.9 110.258V1039.75L1268.22 973.451C1218.94 956.226 1165.18 956.777 1116.26 975.009L716.447 1124.02C677.579 1138.51 634.823 1138.68 595.839 1124.51L-6.6814 905.487V230.747L209.251 147.663C231.514 139.098 256.163 139.098 278.426 147.663L416.345 200.73C466.656 220.088 522.264 220.63 572.943 202.257L1111.77 6.91408C1136.15 -1.92685 1162.9 -1.7261 1187.15 7.47984Z"
                    fill="url(#paint0_linear)"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="725.608" y1="-6.78906" x2="725.607" y2="599.211"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor={props.style.colors.back}/>
                        <stop offset="0.0001" stopColor={props.style.colors.back}/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>

        </div>
    } else
        return <div/>
}


interface IFutureProfBlock {
    name: string
    image: any
    description: string
    color: string
}

function FutureProfBlock(props: IFutureProfBlock) {
    let line_color = props.color.slice(1)
    return <div className="FutureProf_Block" style={{ backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23`+line_color+`FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='33' stroke-linecap='square'/%3e%3c/svg%3e")`}}>

            <div className="FutureProf_Block_Back" style={{backgroundColor: props.color}}/>
            <div className="FutureProf_Block_Name">{props.name}</div>
            <div className="FutureProf_Block_Image"
                 style={{backgroundImage: props.image ?`url(${props.image.s_link})`: "http://www.apimages.com/Images/Ap_Creative_Stock_Header.jpg"}}/>
            <div className="FutureProf_Block_Description">{props.description}</div>
        </div>
    }
