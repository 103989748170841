export default function ChangeDate(a: string) {
    let arr = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];
    let new_a = a.split('-')
    if (new_a[2][0] === '0') new_a[2] = new_a[2][1]
    return (new_a[2] + ' ' + arr[Number(new_a[1]) - 1] + ' ' + new_a[0])
}
