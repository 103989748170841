
import {IFacultyStyleConfig} from "../../Config/faculties.config";
import RounderArrow from './Static/RoundedArrow.svg'
import './why.style.sass'
import {useState} from "react";
import {defaultPicture} from "../../Config/DefaultPicture";

interface IWhyProps {
    style: IFacultyStyleConfig
    reasons: any
    data: any
}
export default function Why(props: IWhyProps) {
    const [colorCircle, setColorCircle] = useState(-1)
    let startPhoto
    const base_line = '#BBBBBB'
    const [showCurrentPhoto, setCurrentPhoto] = useState(null)

    function SizeCircle(size: string) {
        let el: HTMLElement = document.getElementsByClassName("Why_LeftBlock_Arrow_Svg")[0] as HTMLElement;

        el.style.width = size;
        el.style.height = size;
        el.style.transition = '0.5s ease'

    }
    if (props.reasons[0]) {
        if(props.reasons[0].link_picture) startPhoto = props.reasons[0].link_picture.s_link
        else startPhoto = defaultPicture
        return <div>
            <div className="Why_Header">
                <div>Почему {props.data.short_name}?</div>
            </div>
            <div className="Why">
                <div className="Why_LeftBlock">
                    <div className="Why_LeftBlock_Main">
                        <div className="Why_LeftBlock_Arrow">
                            <img src={RounderArrow} alt=''/>
                            <svg className="Why_LeftBlock_Arrow_Svg" width="721" height="712" viewBox="0 0 721 712"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M698.008 437.232C709.684 378.186 706.618 317.172 689.082 259.594C671.545 202.017 640.079 149.652 597.471 107.14C554.863 64.6279 502.429 33.2797 444.812 15.8724C387.195 -1.53481 326.174 -4.46388 267.155 7.34471C208.136 19.1533 152.939 45.335 106.456 83.5708C59.9717 121.807 23.6349 170.916 0.664062 226.549L2.5127 227.313C25.3581 171.983 61.4962 123.142 107.726 85.1154C153.956 47.0886 208.85 21.0499 267.547 9.30584C326.244 -2.43822 386.931 0.474841 444.233 17.787C501.535 35.0991 553.684 66.276 596.059 108.556C638.434 150.836 669.728 202.914 687.168 260.177C704.609 317.44 707.659 378.121 696.046 436.844C684.434 495.567 658.519 550.519 620.596 596.835C582.788 643.009 534.21 679.177 479.139 702.157C479.141 701.916 479.186 701.672 479.28 701.434L482.958 692.13C483.364 691.102 482.86 689.94 481.832 689.534L481.545 689.421C480.518 689.016 479.356 689.52 478.951 690.548L473.698 703.867C473.292 704.895 473.797 706.057 474.824 706.462L488.143 711.714C489.17 712.119 490.332 711.615 490.737 710.587L490.851 710.299C491.256 709.272 490.752 708.11 489.724 707.705L480.406 704.03C480.315 703.994 480.228 703.952 480.145 703.905C535.421 680.793 584.182 644.464 622.143 598.102C660.275 551.532 686.332 496.277 698.008 437.232Z"
                                      fill={colorCircle > -1 ? props.style.colors.baseColor : base_line}/>
                                <circle cx="607.68" cy="117.974" r="22.5"
                                        fill={colorCircle > -1 ? props.style.colors.baseColor : base_line}/>
                                <circle cx="697.678" cy="302.974" r="22.5"
                                        fill={colorCircle > 0 ? props.style.colors.baseColor : base_line}/>
                                <circle cx="694.678" cy="458.974" r="22.5"
                                        fill={colorCircle > 1 ? props.style.colors.baseColor : base_line}/>
                                <circle cx="607.68" cy="614.974" r="22.5"
                                        fill={colorCircle > 2 ? props.style.colors.baseColor : base_line}/>
                            </svg>

                        </div>
                        <div className="Why_LeftBlock_Image" style={{backgroundImage: showCurrentPhoto ? `url(${showCurrentPhoto})`:`url(${startPhoto})` }}/>
                    </div>
                </div>
                <div className="Why_RightBlock">
                    {
                        props.reasons.filter((item: any, index: number) => index < 4).map((item: any, index: number) => {
                            return <div key={index} className="Why_RightBlock_Item" onMouseOver={() =>
                            {
                                SizeCircle('92%');
                                setCurrentPhoto(item.link_picture?.s_link || defaultPicture );
                                setColorCircle(index)
                            }}
                                        onMouseLeave={() => {
                                            SizeCircle('90%');
                                            setColorCircle(-1)
                                        }}
                            >
                                <div className="Why_RightBlock_Item_Info">
                                    <div className="Why_RightBlock_Item_Title"
                                         >{item.name}</div>
                                    <div className="Why_RightBlock_Item_Description"
                                    >{item.description}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    } else
        return <div/>
}

