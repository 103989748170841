import React, {useEffect, useState} from "react";
import {IFacultyStyleConfig} from "../../Config/faculties.config";
import './comments.style.sass'
import {defaultPicture} from "../../Config/DefaultPicture";

interface ICommentsProps {
    style: IFacultyStyleConfig
    direction: any
    review: any
    current_faculty: any
}


function setCommentHeight(){
    let content: HTMLElement | null = document.querySelector('.Comments_Main');
    let container: HTMLElement | null = document.querySelector('.Comments_Main_Block');
    if (content && container) {
        content.style.height=container.offsetHeight + 100 + 'px';
    }
}


export default function Comments(props: ICommentsProps) {
    const [showCurrentComment, setCurrentComment] = useState(0)
    const [showMoreComment, setMoreComment] = useState(false)
    let comments = props.review.filter((comment: any) => comment.faculty === props.current_faculty)

    useEffect(() => {
       setCommentHeight()
    })

    comments.forEach((comment: any, i: number) => {
        if (comments[i].link_picture === null){
            comments[i].link_picture = {
                id: 156
            }
        }
        else return <div key={i}/>
    })
    let main_comment
    main_comment = props.review.find((comment: any, index: number) => index === showCurrentComment)
    if (props.review.length > 0) {
        return <div>
            <div className="Comments" id="reviews">
                <div className="Comments_Header">Отзывы выпускников</div>
                <div className="Comments_Main">
                    <div className="Comments_Main_Block">
                        <MainCommentBlock comment={main_comment} direct={props.direction}/>
                    </div>
                    <div className="Comments_Main_Comments">
                        <div className="Comments_Main_Comments_Row">
                            <div className="Comments_Main_Comments_Row_Img" style={{
                                display: props.review[0] ? 'flex' : 'none',
                                backgroundImage: ( !props.review[0].link_picture.xs_link) ? `url(${defaultPicture})` : `url(${props.review[0].link_picture.xs_link})`,
                                width: '8vw',
                                height: '8vw'
                            }} onMouseEnter={() => setCurrentComment(0)}/>
                            <div className="Comments_Main_Comments_Row_Img" style={{
                                display: props.review[1] ? 'flex' : 'none',
                                backgroundImage: ( !props.review[1]?.link_picture.xs_link) ? `url(${defaultPicture})` : `url(${props.review[1].link_picture.xs_link})`,
                                width: '6vw',
                                height: '6vw'
                            }} onMouseEnter={() => setCurrentComment(1)}/>
                        </div>
                        <div className="Comments_Main_Comments_Row">
                            <div className="Comments_Main_Comments_Row_Img" style={{
                                display: props.review[2] ? 'flex' : 'none',
                                backgroundImage: ( !props.review[2]?.link_picture.xs_link) ? `url(${defaultPicture})` : `url(${props.review[2].link_picture.xs_link})`,
                                width: '6vw',
                                height: '6vw'
                            }} onMouseEnter={() => setCurrentComment(2)}/>
                            <div className="Comments_Main_Comments_Row_Img" style={{
                                display: props.review[3] ? 'flex' : 'none',
                                backgroundImage: ( !props.review[3]?.link_picture.xs_link) ? `url(${defaultPicture})` : `url(${props.review[3].link_picture.xs_link})`,
                                width: '9vw',
                                height: '9vw'
                            }} onMouseEnter={() => setCurrentComment(3)}/>
                        </div>
                        <div className="Comments_Main_Comments_Row">
                            <div className="Comments_Main_Comments_Row_Img" style={{
                                display: props.review[4] ? 'flex' : 'none',
                                backgroundImage: ( !props.review[4]?.link_picture.xs_link) ? `url(${defaultPicture})` : `url(${props.review[4].link_picture.xs_link})`,
                                width: '6vw',
                                height: '6vw'
                            }} onMouseEnter={() => setCurrentComment(4)}/>
                            <div className="Comments_Main_Comments_Row_Img" style={{
                                display: props.review[5] ? 'flex' : 'none',
                                backgroundImage: ( !props.review[5]?.link_picture.xs_link) ? `url(${defaultPicture})` : `url(${props.review[5].link_picture.xs_link})`,
                                width: '6vw',
                                height: '6vw'
                            }} onMouseEnter={() => setCurrentComment(5)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Mobile_Comments">
                <div className="Mobile_Comments_Header" id="reviews">Отзывы выпускников</div>
                <div className="Mobile_Comments_Main">
                    {
                        props.review.map((comment: any, index: number) => {
                            if (index < 1)
                                return <div key={index} className="Mobile_Comments_Main_Block_Shadow">
                                    <div  className="Mobile_Comments_Main_Block">
                                            <MainCommentBlock comment={comment} direct={props.direction}/>
                                    </div>
                                        </div>
                            else return <div key={index}/>
                        })}
                </div>
                <svg className="Mobile_Comments_Arrow" onClick={() => setMoreComment(true)}
                     style={{display: showMoreComment ? "none" : "inline-block"}} width="25" height="25"
                     viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.74106 8.30938C5.03326 8.01132 5.49051 7.98422 5.81272 8.22809L5.90503 8.30938L12.5 15.0369L19.095 8.30938C19.3872 8.01132 19.8444 7.98422 20.1666 8.22809L20.2589 8.30938C20.5511 8.60744 20.5777 9.07385 20.3386 9.40252L20.2589 9.49668L13.082 16.8176C12.7898 17.1156 12.3325 17.1427 12.0103 16.8989L11.918 16.8176L4.74106 9.49668C4.41965 9.16882 4.41965 8.63724 4.74106 8.30938Z"
                        fill={props.style.colors.baseColor}/>
                </svg>

                <div style={{display: showMoreComment ? "inline-block" : "none"}}>
                    {
                        props.review.map((comment: any, index: number) => {
                            if (index > 0)
                                return <div key={index} className="Mobile_Comments_Main_Block_Shadow">
                                    <div  className="Mobile_Comments_Main_Block">
                                            <MainCommentBlock comment={comment} direct={props.direction}/>
                                        </div>
                                </div>
                            else return <div key={index}/>
                        })}
                </div>
            </div>
        </div>
    } else
        return <div/>
}

interface IMainCommentBlockProps {
    comment: any
    direct: any
}




function MainCommentBlock(props: IMainCommentBlockProps) {
    let direct = props.direct.find((current_value: any) => current_value.id === props.comment.direction);
    if (typeof direct === 'undefined') direct = '0'
    return <div className="Comments_Main_Block_Component">
        <div className="Comments_Main_Block_Component_User">
            <div className="Comments_Main_Block_Component_User_Img"
                 style={{backgroundImage: !props.comment.link_picture.xs_link ? `url(${defaultPicture})`: `url(${props.comment.link_picture.xs_link})`}}/>
            <div className="Comments_Main_Block_Component_User_Info">
                <div
                    className="Comments_Main_Block_Component_User_Info_Fio">{props.comment.last_name} {props.comment.first_name}</div>

                <div className="Comments_Main_Block_Component_User_Info_Spec">Направление {direct.kode_direction}</div>
                <div className="Comments_Main_Block_Component_User_Info_Year">Год
                    выпуска {props.comment.date_release.split('-')[0]}</div>
            </div>
        </div>
        <div className="Comments_Main_Block_Component_Comment">{props.comment.feedback}</div>
    </div>
}

