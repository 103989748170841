import {facultiesConfig} from "../Config/faculties.config";


export default function generateConfig(name: string | null) {
    if (name === null) {
        return facultiesConfig.default
    }
    if (!facultiesConfig[name.toUpperCase()]) {
        console.error(`Cant find "${name}" config`)
        return facultiesConfig.default
    }

    return Object.assign({...facultiesConfig.default}, facultiesConfig[name.toUpperCase()])
}
