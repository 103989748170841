
import {
    ERRORS,
    GET_DATES,
    GET_DIRECTION,
    GET_EVENTS,
    GET_FACULTY,
    GET_NEWS,
    GET_REASONS,
    GET_REVIEWS,
    GET_NEWS_TYPE, SET_FACULTY_NAME
} from "../types";
import axios from "axios";
import {BASE_URL} from "../BASE_URL";

export const setFacultyName = (facultyName) => dispatch => {
    dispatch({
        type: SET_FACULTY_NAME,
        payload: facultyName
    })
}

export const getFaculty = (current_faculty) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/?faculty_id=${current_faculty}` )
        let payload
        res.data.forEach((faculty, index) => {
            if (faculty.id === current_faculty) {
                payload = index
            }
        })
        dispatch({
            type: GET_FACULTY,
            payload: res.data[payload]
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}


export const getDirection = (current_faculty) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/programs/?faculty_id=${current_faculty}`)
        dispatch({
            type: GET_DIRECTION,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getNews = (current_faculty) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/news/?faculty_id=` + String(current_faculty))
        dispatch({
            type: GET_NEWS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getReasons = (current_faculty) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/reasons/?faculty_id=${current_faculty}`)
        dispatch({
            type: GET_REASONS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getEvents = (current_faculty) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/event/?faculty_id=${current_faculty}` )
        dispatch({
            type: GET_EVENTS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getReviews = (current_faculty) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/feedback/?faculty_id=${current_faculty}`)
        dispatch({
            type: GET_REVIEWS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getDates = (current_faculty) => async dispatch => {
    try {
        const params = {
            faculty_id: current_faculty
        }
        const res = await axios.get(`${BASE_URL}/faculties/dates/`, {params})
        dispatch({
            type: GET_DATES,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getNewsType = () => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/news/type/`)
        dispatch({
            type: GET_NEWS_TYPE,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}
