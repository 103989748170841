import React, {useEffect} from "react";
import TopMenu from "../../Components/TopMenu/topmenu.component";
import {topMenuConfigDirection} from "../../Config/topMenu.config";
import TopBlockCurve from "../../Components/TopBlockCurve/topBlockCurve.component";
import Breadcrumbs from "../../Components/Breadcrumbs/breadcrumbs.component";
import {facultiesConfig, IFacultyStyleConfig} from "../../Config/faculties.config";
import DirectionInfo from "../../Components/DirectionInfo/directionInfo.component";
import FutureProf from "../../Components/FutureProf/futureProf.component";
import Practise from "../../Components/Practise/practise.component";
import Footer from "../../Components/Footer/footer.component";
import DirectionsCloud from "../../Components/DirectionsCloud/directions-cloud.component";
import {useDispatch, useSelector} from "react-redux";
import Preloader from "../../Components/Preloader/preloader.component";

import { getFaculty } from "../../Store/actions/facultiesAction";
import {
    getExam, getExamScores, getForms,
    getPractices,
    getPrices,
    getProfessions,
    getProgram, getQuotas,
    getScores, getTypes,
    getSubjects
} from "../../Store/actions/directionAction";
import {createGlobalStyle} from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";

interface IDirectionLayoutProps {
    config: IFacultyStyleConfig
}
const helmetContext = {};

const GlobalStyle: any = createGlobalStyle<{ color: string }>`
  * {
    body::-webkit-scrollbar {
      background-color: #EDEDED;
      width: 3px;
    }
  ;

    body::-webkit-scrollbar-thumb {
      background: ${props => props.color}
    }
  }`
export default function DirectionLayout(props: IDirectionLayoutProps) {


    let current_direction = window.location.pathname.split("/").pop()

    const dispatch = useDispatch()
    const faculty = useSelector((state: any) => state.faculty.faculty)
    const program = useSelector((state: any) => state.program.program)
    const professions = useSelector((state: any) => state.professions.professions)
    const practices = useSelector((state: any) => state.practices.practices)
    const exams = useSelector((state: any) => state.exams.exams)
    const scores = useSelector((state: any) => state.scores.scores)
    const prices = useSelector((state: any) => state.prices.prices)
    const types = useSelector((state: any) => state.types.types)
    const quotas = useSelector((state: any) => state.quotas.quotas)
    const forms = useSelector((state: any) => state.forms.forms)
    const examScores = useSelector((state: any) => state.examScores.examScores)
    const subjects = useSelector((state: any) => state.subjects.subjects)


    const isFetchingDirection = useSelector((state: any) => state.isFetchingDirection.isFetchingDirection)

    topMenuConfigDirection[1][2] = professions.length !== 0
    topMenuConfigDirection[2][2] = practices.length !== 0;
    topMenuConfigDirection[3][2] = subjects.length !== 0

    useSelector((state: any) => state.exams.exams);
    useEffect(() => {
        dispatch(getProgram(current_direction));
        dispatch(getFaculty(props.config.id));
        dispatch(getProfessions(current_direction));
        dispatch(getPractices(current_direction));
        dispatch(getScores(current_direction));
        dispatch(getExam());
        dispatch(getPrices(current_direction));
        dispatch(getTypes());
        dispatch(getQuotas(current_direction));
        dispatch(getForms(current_direction));
        dispatch(getExamScores(current_direction));
        dispatch(getSubjects(current_direction))


    }, [current_direction, dispatch, props.config.id]);
    if (!faculty) return <div/>
    if(!program){
        window.location.assign(window.location.href.substring(0, window.location.href.lastIndexOf('/')))
        return <></>
    }
    if ((program.faculty)&&(faculty.id)) {
        if ((faculty.id === program.faculty)) {
            return <>
                {isFetchingDirection.every((element: boolean) => element) ?
                    <HelmetProvider context={helmetContext}>
                        <div>
                            <Helmet>
                                <meta charSet="utf-8"/>
                                <title>{`${program.kode_direction} | СПбГУТ`}</title>
                            </Helmet>
                            <GlobalStyle color={props.config.colors.header}/>
                            <TopMenu buttons={topMenuConfigDirection} style={props.config}/>
                            <TopBlockCurve style={props.config}/>
                            <Breadcrumbs style={props.config} directionName={program} facultyName={faculty}/>
                            <DirectionInfo style={props.config} directionInfo={program}
                                           exams={exams}
                                           scores={scores}
                                           prices={prices}
                                           types={types}
                                           quotas={quotas}
                                           forms={forms}
                                           examScores={examScores}
                                           current_direction={Number(current_direction)}/>
                            <FutureProf style={props.config} profession={professions}
                                        current_direction={Number(current_direction)}/>
                            <Practise style={props.config} practice={practices}
                                      current_direction={Number(current_direction)}/>
                            <DirectionsCloud style={props.config} subjects={subjects}/>
                            <Footer style={props.config} faculties={facultiesConfig} data={faculty}/>

                        </div>
                    </HelmetProvider>
                    :<Preloader/> }
                </>
        }
        else {
            window.location.assign(window.location.href.substring(0, window.location.href.lastIndexOf('/')))
            return <></>
        }
    }
    else {
        return <></>
    }

}
