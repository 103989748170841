import React, {useEffect} from "react";
import TopMenu from "../../Components/TopMenu/topmenu.component";
import { topMenuConfigFaculty } from "../../Config/topMenu.config";
import TopBlockPhoto from "../../Components/TopBlockPhoto/topBlockPhoto.components";
import AboutFaculty from "../../Components/AbouFaculty/aboutFaculty.component";
import Directions from "../../Components/Directions/directions.component";
import Admission from "../../Components/Admission/admission.component";
import News from "../../Components/News/news.component";
import Why from "../../Components/Why/why.component";
import Sovet from "../../Components/Sovet/sovet.component";
import Comments from "../../Components/Comments/comments.component";
import Footer from "../../Components/Footer/footer.component";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import Preloader from '../../Components/Preloader/preloader.component'

import {
    getDates,
    getDirection,
    getEvents,
    getFaculty,
    getNews, getNewsType,
    getReasons,
    getReviews
} from "../../Store/actions/facultiesAction";
import {useDispatch, useSelector} from "react-redux";
import {createGlobalStyle} from "styled-components";
import {facultiesConfig} from "../../Config/faculties.config";

interface IMainLayoutProps {
    config: any
}
const helmetContext = {};

const GlobalStyle: any = createGlobalStyle<{ color: string }>`
  * {

    body::-webkit-scrollbar {
      background-color: #EDEDED;
      width: 3px;
    }
  ;

    body::-webkit-scrollbar:horizontal {
      height: 0;
    }
  ;

    body::-webkit-scrollbar-thumb {
      background: ${props => props.color};
      height: 0;
    }

    div::-webkit-scrollbar {
      background-color: #EDEDED;
      width: 3px;
    }
  ;

    div::-webkit-scrollbar-thumb {
      background: ${props => props.color}
    }
  }`

export default function MainLayout(props: IMainLayoutProps) {
    const dispatch = useDispatch()
    const faculty = useSelector((state: any) => state.faculty.faculty)
    const directions = useSelector((state: any) => state.direction.direction)
    const news = useSelector((state: any) => state.news.news)
    const news_type = useSelector((state: any) => state.news_type.news_type)
    const reasons = useSelector((state: any) => state.reasons.reasons)
    const events = useSelector((state: any) => state.events.events)
    const reviews = useSelector((state: any) => state.reviews.reviews)
    const dates = useSelector((state: any) => state.dates.dates)

    const isFetching = useSelector((state: any) => state.isFetchingFaculty.isFetchingFaculty)

    topMenuConfigFaculty[2][2] = news.length !== 0
    topMenuConfigFaculty[3][2] = events.length !== 0
    topMenuConfigFaculty[4][2] = reviews.length !== 0


    useEffect(() => {
        dispatch(getDirection(props.config.id));
        dispatch(getNews(props.config.id));
        dispatch(getReasons(props.config.id));
        dispatch(getEvents(props.config.id));
        dispatch(getReviews(props.config.id));
        dispatch(getFaculty(props.config.id));
        dispatch(getDates(props.config.id));
        dispatch(getNewsType())

    }, [dispatch, props.config.id]);
    if (!faculty) return <div/>
    return <>
        {
            isFetching.every((element: boolean) => element) ?
    <HelmetProvider context={helmetContext}>
    <div className="MainLayout">
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{`${faculty.short_name} | СПбГУТ`}</title>
            <link rel="icon" type="image/png" sizes="64"
                  href={require("./../Public/" + props.config.id.toString() + ".ico").default}/>
        </Helmet>
        <GlobalStyle color={props.config.colors.header}/>
        <TopMenu buttons={topMenuConfigFaculty} style={props.config} />
        <TopBlockPhoto data={faculty} style={props.config}/>
        <AboutFaculty data={faculty} style={props.config}/>
        <Directions directions={directions} style={props.config} current_faculty={props.config.id} />
        <Admission style={props.config} steps={dates}/>
        <News style={props.config} news={news} type={news_type} current_faculty={props.config.id}/>
        <Why style={props.config} reasons={reasons} data={faculty}/>
        <Sovet data={faculty} style={props.config} events={events}/>
        <Comments style={props.config} review={reviews} current_faculty={props.config.id} direction={directions}/>
        <Footer style={props.config} faculties={facultiesConfig} data={faculty}/>
    </div>
    </HelmetProvider>
            : <Preloader/>}
        </>
}

