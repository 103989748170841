import update from "react-addons-update";
import {
    GET_DATES,
    GET_DIRECTION,
    GET_EVENTS,
    GET_FACULTY,
    GET_NEWS,
    GET_REASONS,
    GET_REVIEWS,
    GET_NEWS_TYPE, SET_FACULTY_NAME
} from "../types";

const initialState = {
    facultyName: '',
    direction: [],
    news: [],
    reasons: [],
    events: [],
    reviews: [],
    faculty: {},
    dates: [],
    news_type: [],
    isFetchingFaculty: [false, false, false, false, false]
}
export default function facultiesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FACULTY_NAME:
            return {
                ...state,
                facultyName: action.payload
            }
        case GET_DIRECTION:
            return update(state, {
                direction: {
                    $set: action.payload
                },
                isFetchingFaculty: {
                    0:{
                        $set: true
                    }
                }
            })
        case GET_NEWS:
            return update(state, {
                news: {
                    $set: action.payload
                },
                isFetchingFaculty: {
                    1:{
                        $set: true
                    }
                }
            })
        case GET_REASONS:
            return update(state, {
                reasons: {
                    $set: action.payload
                },
                isFetchingFaculty: {
                    2:{
                        $set: true
                    }
                }
            })
        case GET_EVENTS:
            return update(state, {
                events: {
                    $set: action.payload
                },
                isFetchingFaculty: {
                    3:{
                        $set: true
                    }
                }
            })
        case GET_REVIEWS:
            return update(state, {
                reviews: {
                    $set: action.payload
                },
                isFetchingFaculty: {
                    4:{
                        $set: true
                    }
                }
            })
        case GET_FACULTY:
            return {
                ...state,
                faculty: action.payload
            }
        case GET_DATES:
            return {
                ...state,
                dates: action.payload
            }
        case GET_NEWS_TYPE:
            return {
                ...state,
                news_type: action.payload
            }
        default:
            return state
    }
}


