import {IFacultyStyleConfig} from "../../Config/faculties.config";

import './breadcrumbs.style.sass'



import line from './Static/line.svg'

interface IBreadcrumbsProps {
    style: IFacultyStyleConfig
    facultyName: any
    directionName: any
}


export default function Breadcrumbs(props: IBreadcrumbsProps) {
    function assign() {
        window.location.assign(window.location.href.substring(0, window.location.href.lastIndexOf('/')))
    }

    return <div className="Breadcrumbs">
        <div className="Breadcrumbs_faculty" onClick={() => assign()}> {props.facultyName.short_name} </div>
        &nbsp; <img src={line} alt=''/> &nbsp;
        <div>{props.directionName.kode_direction}</div>
    </div>
}
