import './ModalWindowEvent.style.sass'
import React, {useState} from "react";
import {defaultPicture} from "../../Config/DefaultPicture";

const ModalWindow = (showModule: boolean, setShowModule: any, event: any, style: any, current_season: string) => {
    let currentPhoto
    function close_back(e:any){
        if (e.target.classList.contains('Modal_Back')){
            document.body.style.overflowY = 'scroll';
            setShowModule(false)
        }
    }
    if (event.link_picture.length) {
        currentPhoto = event.link_picture[0].s_link
    } else currentPhoto = defaultPicture
    const [setActivePhoto, setShowActivePhoto] = useState(currentPhoto)
    return <div className="Modal_Back" onClick={close_back} style={{display: showModule ? "block" : "none"}}>

        <div className="ModalWindow">
            <svg className='Exit' width="20" height="21" viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg" onClick={() => {
                document.body.style.overflowY = 'scroll';
                setShowModule(false);
            }}>
                <circle cx="10" cy="10.0419" r="10" fill={style.baseColor}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.0915 15.0419L4.64648 13.6138L4.64751 13.6127L8.25749 10.0389L4.64853 6.46496L6.08639 5.04187L9.99989 8.90655L13.9134 5.04187L15.3513 6.46496L11.7423 10.0389L15.3523 13.6127L15.3533 13.6138L13.9083 15.0419L9.99989 11.1737L6.0915 15.0419Z"
                      fill="white"/>
            </svg>
            <div className="Modal">
                <svg className="Modal_Background" width="100%" height="100%" preserveAspectRatio="none"
                     viewBox="0 0 836 316" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M-19.0137 13.992C-19.0137 6.28756 -12.768 0.0418701 -5.06351 0.0418701H842.842C850.546 0.0418701 856.792 6.28757 856.792 13.992V165.673C856.792 171.754 852.854 177.133 847.058 178.971L799.758 193.969C792.068 196.407 790.484 206.588 797.068 211.248L818.149 226.167C822.769 229.436 821.777 236.557 816.439 238.439L615.765 309.197C588.554 318.791 558.554 316.434 533.175 302.707L474.863 271.167C472.825 270.064 470.544 269.487 468.227 269.487H-5.06351C-12.768 269.487 -19.0137 263.241 -19.0137 255.537V13.992Z"
                        fill={style.back} opacity='0.3'/>
                </svg>
                <div className="Modal_Top">
                    <div className="Modal_Top_Title">
                        <div className="Modal_Background_Square"/>
                        <div className="Modal_Top_Title_Img"
                             style={{backgroundImage: `url(${setActivePhoto})`}}/>
                        <div className="Modal_Top_Title_Block">
                            <div className="Modal_Top_Title_Block_Event">
                                <div className="Modal_Top_Title_Block_Event_Namefor">
                                    <div className="Modal_Top_Title_Block_Event_Name">{event.name}</div>
                                    <div
                                        className="Modal_Top_Title_Block_Event_Date">{current_season}</div>
                                </div>
                            </div>
                            {
                                (event.link_picture.length > 1)
                                    ? <div className="Modal_Top_Title_Block_Scroll">
                                        {
                                            event.link_picture.map((picture: any, index: number) => {
                                                if (index > 0) {
                                                    return <div key={index} className="Modal_Top_Title_Block_Scroll_Block"
                                                                onClick={() => setShowActivePhoto(picture.s_link)}>
                                                        <div className="Modal_Top_Title_Block_Scroll_Block_Image"
                                                             style={{backgroundImage: `url(${picture.xs_link})`}}/>
                                                    </div>
                                                }
                                                else return <div key={index}/>
                                            })
                                        }
                                        <div className="Modal_Top_Title_Block_Scroll_Block"
                                             onClick={() => setShowActivePhoto(event.link_picture[0].s_link)}>
                                            <div className="Modal_Top_Title_Block_Scroll_Block_Image"
                                                 style={{backgroundImage: `url(${event.link_picture[0].xs_link})`}}/>
                                        </div>
                                    </div>
                                    : <div/>
                            }
                        </div>
                    </div>
                </div>
                <div className="Modal_Description">
                    <div className="Modal_Description_Text">{event.description}</div>
                </div>

            </div>

        </div>
    </div>
}

export default ModalWindow
