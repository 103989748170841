
import {IFacultyStyleConfig} from "../../Config/faculties.config";

import './practise.style.sass'


interface IPractiseProps {
    style: IFacultyStyleConfig
    practice: any
    current_direction: number
}
export default function Practise(props: IPractiseProps) {
    if (props.practice.length > 0) {
        return <div className="Practise" id="practice">
            <div className="Practise_HeadText">Практика</div>
            <div className="Practise_Blocks">
                {props.practice.map((practise: any, index: number) => {
                    if (practise.link_picture) {
                        return <PractiseBlock key={index} name={practise.name} description={practise.description}
                                              image={practise.link_picture.s_link}/>}
                    else return <div/>
                })}
            </div>
        </div>
    } else
        return <div/>
}


function PractiseBlock(props: any) {
        return <div className="Practise_Blocks_Block">
            <div className="Practise_Blocks_Block_Image"
                 style={{backgroundImage: `url(${props.image})`}}
            />
            <div className="Practise_Blocks_Block_Name">{props.name}</div>
            <div className="Practise_Blocks_Block_Description">{props.description}</div>
        </div>
}
