import {IFacultyStyleConfig} from "../../Config/faculties.config";
import './topBlockCurve.styles.sass'
import React from "react";

interface ITopBlockPhotoProps {
    style: IFacultyStyleConfig
}


export default function TopBlockCurve(props: ITopBlockPhotoProps) {

    return <div className="TopBlockCurve">
        <svg className="TopBlockCurve_image" width={window.screen.width > 500 ? "130%" : "300%"} height="100%"
             viewBox={window.screen.width > 1024 ? "30 590 1440 850" : "300 550 1440 850"} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M159.261 687.286L-0.210938 620.538V-25.8687L1439.79 -12.8756V620.538L1355.87 660.855C1346.32 665.447 1345.9 678.904 1355.15 684.084L1385.9 701.296C1393.15 705.355 1392.4 716.024 1384.65 719.025L1072.73 839.786C1037.7 853.348 998.799 852.935 964.065 838.634L498.16 646.798C457.332 629.987 411.491 630.12 370.761 647.168L274.912 687.286C237.917 702.771 196.256 702.771 159.261 687.286Z"
                fill="url(#paint55_linear)"/>

            <defs>
                <linearGradient id="paint55_linear" x1="-213.369" y1="35.0147" x2="645.669" y2="788.823"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor={props.style.colors.gradientColor[0]}/>
                    <stop offset="0.483852" stopColor={props.style.colors.gradientColor[1]}/>
                    <stop offset="0.909034" stopColor={props.style.colors.gradientColor[2]}/>
                </linearGradient>
            </defs>
        </svg>
    </div>
}
