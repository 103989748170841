import {IFacultyStyleConfig} from "../../Config/faculties.config";

import './aboutFaculty.styles.sass'
import {createGlobalStyle} from "styled-components";
import React from "react";


interface IAboutFacultyProps {
    data: any
    style: IFacultyStyleConfig
}


const GlobalStyle: any = createGlobalStyle<{ color: string }>`
*{
    li::before{
        background-color: ${props => props.color};
    }
    
}`

export default function AboutFaculty(props: IAboutFacultyProps) {
    if (props.data !== undefined) {

        return <div className="AboutFaculty">
            <GlobalStyle color={props.style.colors.baseColor}/>
            <div className="AboutFaculty_HeadText" id="about">О факультете</div>
            <div className="AboutFaculty_MainText">
            </div>
            <div className="AboutFaculty_MainText" dangerouslySetInnerHTML={{
                __html: props.data.description
            }}/>
            <svg className="AboutFaculty_BackPic" width="346" height="343" viewBox="0 0 346 343" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M455.778 40.7794L560.479 1.16309V341.69L396.193 269.025C380.806 262.22 363.305 262.006 347.757 268.432L248.443 309.482C240.641 312.707 231.874 312.686 224.087 309.424L116.425 264.322C110.769 261.953 110.431 254.067 115.863 251.222L128.349 244.681C133.096 242.195 132.808 235.306 127.871 233.224L3.82941 180.917C-1.34802 178.734 -1.33624 171.393 3.84821 169.226L331.094 32.4667C345.767 26.3346 362.262 26.208 377.027 32.1143L397.318 40.2305C416.049 47.7231 436.909 47.919 455.778 40.7794Z"
                    stroke={props.style.colors.back} strokeDasharray="15 15"/>
            </svg>
        </div>
    } else
        return <div/>
}