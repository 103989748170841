import axios from "axios";
import {
    ERRORS,
    GET_EXAM,
    GET_EXAMSCORES,
    GET_FORMS,
    GET_PRACTICES,
    GET_PRICES,
    GET_PROFESSIONS,
    GET_PROGRAM,
    GET_QUOTAS,
    GET_SCORES,
    GET_TYPES,
    GET_SUBJECTS
} from "../types";
import {BASE_URL} from "../BASE_URL";

export const getProgram = (current_direction) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/programs/?direction_id=${current_direction}` )
        let pay
        res.data.forEach((program, index) => {
            if (program.id === Number(current_direction)) {
                pay = index
            }
        })
        dispatch({
            type: GET_PROGRAM,
            payload: res.data[pay]
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getProfessions = (current_direction) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/programs/professions/?direction_id=${current_direction}`)
        dispatch({
            type: GET_PROFESSIONS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getPractices = (current_direction) => async dispatch => {
    try {
        const res = await axios.get( `${BASE_URL}/faculties/programs/practices/?direction_id=${current_direction}`)
        dispatch({
            type: GET_PRACTICES,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getExam = () => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/programs/exam-subjects/`)
        dispatch({
            type: GET_EXAM,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}


export const getScores = (current_direction) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/programs/passing-scores/?direction_id=${current_direction}` )
        dispatch({
            type: GET_SCORES,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getPrices = (current_direction) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/faculties/programs/prices/?direction_id=${current_direction}` )
        dispatch({
            type: GET_PRICES,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getTypes = () => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/education-forms/types/`)
        dispatch({
            type: GET_TYPES,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getQuotas = (current_direction) => async dispatch => {
    try {
        const res = await axios.get( `${BASE_URL}/faculties/programs/quotas/?direction_id=${current_direction}`)
        dispatch({
            type: GET_QUOTAS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getForms = (current_direction) => async dispatch => {
    try {
        const res = await axios.get(`${BASE_URL}/education-forms/?direction_id=${current_direction}`)
        dispatch({
            type: GET_FORMS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getExamScores = (current_direction) => async dispatch => {
    try {
        const res = await axios.get( `${BASE_URL}/faculties/programs/minimal-scores/?direction_id=${current_direction}`)
        dispatch({
            type: GET_EXAMSCORES,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}

export const getSubjects = (current_direction) => async dispatch => {
    try {
        const params = {
            direction_id: current_direction
        }
        const res = await axios.get(`${BASE_URL}/faculties/programs/subjects/`, { params })
        dispatch({
            type: GET_SUBJECTS,
            payload: res.data
        })
    } catch (e) {
        dispatch({
            type: ERRORS,
            payload: e,
        })
    }
}
