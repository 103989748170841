
import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown} from "@fortawesome/free-solid-svg-icons";

import {HashLink as Link} from 'react-router-hash-link';
import './topmenu.styles.sass'


interface ITopMenuProps {
    buttons: [string, string, boolean][],
    style: any,
}


export default function TopMenu(props: ITopMenuProps) {
    const {buttons} = props
    const [selected] = useState<number>(0)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setShowPopup(false));



    return <div className="TopMenu">
        <div className="TopMenu_mobile">
            <div className="TopMenu_mobile_selectBlock" onClick={() => setShowPopup(true)}>
                <div className="TopMenu_mobile_selectBlock_text">{buttons[selected][0]}</div>
                <div className="TopMenu_mobile_selectBlock_button"><FontAwesomeIcon icon={faSortDown}/></div>
            </div>
            {TopMenuMobileHover(props.style.colors.baseColor, buttons, showPopup, setShowPopup, wrapperRef)}
        </div>
        <div className="TopMenu_desktop">{MenuButtons(props.buttons)}</div>
    </div>
}

const TopMenuMobileHover = (backColor: string, buttons: [string, string, boolean][], showPopup: boolean, setShowPopup: any, wrapperRef: React.MutableRefObject<any>) => {
    return <div ref={wrapperRef} className="TopMenu_mobile_hover"
                style={{display: showPopup ? "inline-block" : "none", backgroundColor: backColor}}>
        {buttons.filter((button: any) => button[2]).map((button, index) => {
                if (index === 0) {
                    return <Link className="TopMenu_mobile_hover_link" key={index} to={'#'}
                                 onClick={() => setShowPopup(false)}>
                        <div className="TopMenu_mobile_hover_button">{button[0]}{index === 0 ?
                            <FontAwesomeIcon className="TopMenu_mobile_hover_icon" icon={faSortDown}/> : null}</div>
                    </Link>
                } else {
                    return <Link className="TopMenu_mobile_hover_link" key={index}
                                 to={button[1]}
                                 onClick={() => setShowPopup(false)}>
                        <div className="TopMenu_mobile_hover_button">{button[0]}{index === 0 ?
                            <FontAwesomeIcon className="TopMenu_mobile_hover_icon" icon={faSortDown}/> : null}</div>
                    </Link>
                }
        })}
    </div>
}

const MenuButtons = (buttons: [string, string, boolean][]) => {
     return buttons.filter((button: any) => button[2]).map((button, index) => {
        return <Link id="Id_Button"
            to={button[1]}
            key={index} className="TopMenu_button" replace>{button[0]}</Link>
     })
}

function useOutsideAlerter(ref: React.MutableRefObject<any>, action: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: { target: any; }) {
            if (ref.current && !ref.current.contains(event.target)) {
                action()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref,action]);
}

