import {combineReducers} from "redux";
import facultiesReducer from './facultiesReducer'
import directionReducer from "./directionReducer";

export default combineReducers({
    faculties: facultiesReducer,
    faculty: facultiesReducer,
    direction: facultiesReducer,
    news: facultiesReducer,
    reasons: facultiesReducer,
    events: facultiesReducer,
    reviews: facultiesReducer,
    dates: facultiesReducer,
    news_type: facultiesReducer,
    isFetchingFaculty: facultiesReducer,


    program: directionReducer,
    professions: directionReducer,
    practices: directionReducer,
    exams: directionReducer,
    scores: directionReducer,
    prices: directionReducer,
    types: directionReducer,
    quotas: directionReducer,
    forms: directionReducer,
    examScores: directionReducer,
    subjects: directionReducer,
    isFetchingDirection: directionReducer
})
export type combineReducersType = ReturnType<typeof combineReducers>

